<template>
  <div class="open_details">
    <div class="details_info">
      <div class="nav_return">
        <el-button @click="$router.go(-1)" type icon="el-icon-arrow-left"
          >返回标签列表</el-button
        >
      </div>
      <div>
        <el-alert
          v-if="[1, 2, 3].includes(food_status)"
          type="info"
          show-icon
          :closable="false"
        >
          订单状态：{{ food_status | orderStatus }}
        </el-alert>
        <el-alert
          v-else-if="[4].includes(food_status)"
          type="success"
          show-icon
          :closable="false"
        >
          订单状态：{{ food_status | orderStatus }}
        </el-alert>

        <el-alert v-else type="primary" show-icon :closable="false">
          订单状态：{{ food_status | orderStatus }}
        </el-alert>
      </div>
      <div class="details_box">
        <div class="table_title">订单概要</div>
        <el-table
          :data="table1"
          border
          style="width: 100%"
          :show-header="false"
        >
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.title }}</template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.info }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="details_box">
        <div class="table_title">订单详情</div>
        <div class="box_list">
          <el-table
            :data="table2"
            border
            style="width: 100%"
            :show-header="false"
          >
            <el-table-column>
              <template slot-scope="scope">
                <div v-if="scope.row.url">
                  <div>产品标准文件：</div>
                  <div :key="index" v-for="(item, index) in scope.row.url">
                    <a
                      :href="item.url"
                      download
                      style="color: #099fbc"
                      class="iconfont iconxiazai"
                      target="_Blank"
                      >{{ item.originalname }}</a
                    >
                  </div>
                </div>
                <span v-else>{{ scope.row.title }}</span>
              </template>
            </el-table-column>
          </el-table>

          <div class="table_img">
            <div>
              <div class="title tac fwb">（点击图片 / 文件可查看详情）</div>
              <div class="title tac" v-if="file_list">
                <div class="btn dib" v-for="item in file_list" :key="item.id">
                  <a :href="item.url" download target="_Blank">
                    <el-button
                      type="text"
                      style="padding: 5px; margin: 0 15px"
                      icon="el-icon-download"
                      >{{ item.originalname }}</el-button
                    >
                  </a>
                </div>
              </div>
            </div>
            <div class="img_list" v-if="table_img.length !== 0">
              <el-row :gutter="5" style="width: 100%">
                <el-col
                  :span="4"
                  v-for="(item, index) in table_img"
                  :key="index"
                >
                  <img
                    class="tac"
                    :src="item.url"
                    :alt="item.originalname"
                    :large="item.url"
                    :title="item.originalname"
                    v-preview="item.url"
                    preview-title-enable="true"
                    preview-nav-enable="true"
                    :preview-text="item.originalname"
                    width="100%"
                    style="margin-bottom: 5px"
                  />
                </el-col>
              </el-row>
            </div>
          </div>

          <el-row>
            <el-col span="8">
              <div class="more_text" v-if="report_list">
                <span>审核建议书：</span>
                <span class="text" v-if="report_list">
                  <a @click="openUrl">
                    <el-button type="text" icon="el-icon-download"
                      >{{ report_list.originalname }}
                    </el-button>
                  </a>
                </span>
              </div>
            </el-col>
            <el-col span="8">
              <div class="more_text" v-if="rid">
                <span>检验报告：</span>
                <span class="text" v-if="rid">
                  <el-button
                    type="text"
                    icon="el-icon-download"
                    @click="downloadReport"
                    >{{ rid + '.pdf' }}
                  </el-button>
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { moment_set } from '@/assets/js/validate';
export default {
  data() {
    return {
      table1: [],
      table2: [],
      table_img: '',
      food_status: '',
      file_list: '',
      report_list: '',
      rid: '',
    };
  },
  methods: {
    openUrl() {
      window.open(this.report_list.url, '_Blank');
    },
    downloadReport() {
      this.$api.download_cma(this.rid).then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = this.rid + '.pdf';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
  },
  mounted() {
    this.$api.orders_get(this.$route.query.id).then((res) => {
      const order = res.data.order;
      const OrderDetail = res.data.orderDetail;
      const checkResult = res.data.checkResult;

      if (res.data.cmaRID) {
        this.rid = res.data.cmaRID;
      }
      this.table1 = [];
      this.table1 = [
        {
          title: `订单编号：${order.tid}`,
          info: `优惠抵扣：${
            order.couponCodes.length
              ? `[ ${order.couponCodes.join(',')} ] ${
                  order.couponCodes.length
                }张优惠券：- ￥${order.couponDiscountInOrder}
              `
              : '未使用'
          }`,
        },

        {
          info: `订单价格：${order.total_fee}`,
          title: `创建时间 ：${moment_set(order.createAt)}`,
        },
      ];

      if (order.showStatus !== 6) {
        if (order.pay_status === 0) {
          this.table1.push({
            info: `支付时间：${moment_set(order.pay_time)}`,
            title: `支付状态 ： 待付款`,
          });
        } else {
          this.table1.push({
            info: `支付时间：${moment_set(order.pay_time)}`,
            title: `支付状态 ：已付款 ￥${order.payment}  = （余额抵扣：￥ ${
              order.balance
            }，微信支付：￥${order.payment - order.balance}）`,
          });
        }
      }

      if (order.adjust_fee !== 0) {
        this.table1.push({
          info: `调整原因：${order.adjust_remark || ''}`,
          title: `价格调整：优惠￥ ${order.adjust_fee}`,
        });
      }
      if (order.refund_status !== 0) {
        this.table1.push(
          {
            info: `返款时间：${moment_set(order.refund_time)}`,
            title: `返款状态 ：${
              order.refund_status === 0 ? '-' : '已返款'
            } ￥${order.refund_amount}  = （返至账户余额：￥${
              order.refund_amount
            }）`,
          },
          {
            info: ``,
            title: `返款原因：${order.refund_remark}`,
          }
        );
      }
      this.table2 = [
        {
          title: `食品名称：${OrderDetail.foodName}`,
        },
        {
          title: `产品规格：${OrderDetail.specs ? OrderDetail.specs : ''}`,
        },
        {
          title: `生产商（或受委托生产企业）名称：${
            OrderDetail.manufacturer ? OrderDetail.manufacturer : ''
          }`,
        },
        {
          title: `产品标准文件：`,
          url:
            OrderDetail.productStandard &&
            OrderDetail.productStandard.standardFile,
        },
        {
          title: `客户备注：${OrderDetail.remark}`,
        },
      ];
      this.food_status = order.showStatus;
      // 商品图
      this.table_img = [];
      this.file_list = [];
      if (checkResult) {
        this.report_list = checkResult.report || '';
      }
      OrderDetail.foodLabels.forEach((e) => {
        if (e.isImage === true) {
          this.table_img.push(e);
        } else if (e.isImage === false) {
          this.file_list.push(e);
        }
      });
    });
  },
};
</script>
<style lang="scss">
.open_details {
  .details_title {
    .title {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .details_info {
    // padding: 20px;
    .nav_return {
      // background: #ffffff;
      margin: 10px 0;
    }
    .details_box {
      background: #ffffff;
      margin: 10px 0;
      .box_list {
        padding-bottom: 20px;
      }
      .hint {
        padding: 20px;
        // font-size: 13px;
        .num {
          color: #42b983;
          font-weight: bold;
        }
      }
      .table_title {
        background: grey;
        line-height: 35px;
        padding: 0 20px;
      }
      .more_text {
        padding: 0 20px;
        font-size: 15px;
      }
      .table_img {
        font-size: 0;
        line-height: 0;
        padding: 10px 0;
        .title {
          font-size: 15px;
          line-height: 20px;
        }
        .img_list {
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-box;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          /* 水平居中*/
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-pack: center; /* IE 10 */
          -webkit-justify-content: center;
          -moz-justify-content: center;
          justify-content: center; /* IE 11+,Firefox 22+,Chrome 29+,Opera 12.1*/
          /* 垂直居中 */
          -webkit-box-pack: center;
          -moz-box-pack: center;
          -ms-flex-align: center; /* IE 10 */
          background: #ffffff;
          font-size: 0;
          line-height: 0;
        }
        ul {
          width: 100%;
          li {
            width: 20%;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
            img {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
